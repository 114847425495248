.about-page {
  margin-left: 92px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-height: 100vh;
  // height: 100vh;

  .text-zone {
    max-width: 650px;
    h1 {
      font-size: 60px;
      line-height: 60px;
      font-family: 'Coolvetica';
      font-weight: 400;
      letter-spacing: 5px;
      color: #ec795a;
      position: relative;
      margin-bottom: 50px;

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #ec795a;
        font-size: 18px;
        position: absolute;
        margin-top: -15px;
        left: -20px;
        opacity: 0.6;
        line-height: 18px;
        letter-spacing: 2px;
      }

      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #ec795a;
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -40px;
        bottom: -25px;
        margin-left: 20px;
        opacity: 0.6;
        letter-spacing: 2px;
      }
    }

    p {
      font-size: 16px;
      color: #f5f3f4;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }

    .text-animate-hover {
      &:hover {
        color: #f5f3f4;
      }
    }
  }

  .stage-cube-cont {
    // margin-left: 300px;
    // margin-bottom: 150px;
  }

  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    // margin-left: calc(50% - 100px);
    margin-bottom: 100px;
    margin-right: 300px;
    position: relative;

    div {
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px lightyellow;
    }

    .face1 {
      transform: translateZ(100px);
    }
    .face2 {
      transform: rotateY(90deg) translateZ(100px);
      color: #f06529;
    }
    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      color: #28a4d9;
    }
    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      color: #5ed4f4;
    }
    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      color: #efd81d;
    }
    .face6 {
      transform: rotateX(-90deg) translateZ(100px);
      color: #ec4d28;
    }
  }
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateX(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}

@media screen and (max-width: 768px) {
  .about-page {
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;
    // height: 110vh !important;
    .text-zone {
      h1 {
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 3px;
        margin-bottom: 50px;
      }
      p {
        font-size: 18px;
      }
    }
    .stage-cube-cont {
      margin-bottom: 100px;
      .cubespinner {
        margin-right: 220px;
      }
    }
  }

  //   .text-zone {
  //     width: 80% !important;
  //   }
  //   .stage-cube-cont {
  //     // position: absolute;
  //     width: 90%;
  //     top: -90px;
  //     right: 25px;
  //     size: 20px;
  //     opacity: 0.4;
  //   }
}
