@import 'animate.css';
@import '~loaders.css/src/animations/pacman.scss';

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}
input,
textarea {
  font-family: 'Helvetica Neue', sans-serif;
}

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}

.flat-button {
  font-size: 20px;
  letter-spacing: 1px;
  text-decoration: none;
  color: #f5f3f4;
  background: #e05f3c;
  cursor: pointer;
  border: 3px solid #e05f3c;
  padding: 0.25em 0.75em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:hover {
    background: #f5f3f4;
    color: #e05f3c;
    border: 3px solid #f5f3f4;
  }
}

@media screen and (max-width: 768px) {
  .flat-button {
    font-size: 18px;
    letter-spacing: 1px;
  }
}
