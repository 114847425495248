.nav-bar {
  // background: #161513;
  width: 92px;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 3;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logo {
    display: block;
    padding: 20px 0;

    @keyframes shake {
      0%,
      100% {
        transform: translateX(0);
      }
      25% {
        transform: translateX(-5px);
      }
      50% {
        transform: translateX(5px);
      }
      75% {
        transform: translateX(-3px);
      }
    }

    img {
      display: block;
      margin: 8px auto;
      width: 34px;
      height: auto;
      // animation: rotateIn 1s linear both;
      animation: shake 0.5s ease-in-out both;
    }
  }

  nav {
    display: block;
    text-align: center;
    padding-top: 50px;
    // position: absolute;
    // top: 36%;
    width: 100%;

    a {
      font-size: 34px;
      margin-bottom: 10px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #e05f3c;

        svg {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }
      }

      &::after {
        content: '';
        font-size: 14px;
        letter-spacing: 1px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &::after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &::after {
        content: 'ABOUT';
      }
    }
    a.projects-link {
      &::after {
        content: 'PROJECTS';
      }
    }

    a.contact-link {
      &::after {
        content: 'CONTACT';
      }
    }

    a.active {
      svg {
        color: #e05f3c;
      }
    }
  }

  ul {
    // position: absolute;
    // bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0 0 10px 0;
  }

  li {
    a {
      padding: 9px 0;
      display: block;
      font-size: 28px;
      line-height: 16px;
      color: #4d4d4e;

      &:hover svg {
        color: #e05f3c;
      }
    }
  }
}

.hamburger-icon,
.close-icon {
  display: none;
}

@media screen and (max-width: 768px) {
  .nav-bar {
    background: transparent;
    position: initial;
    height: auto;
    min-height: auto;

    ul,
    nav {
      display: none;
    }

    nav {
      width: 100%;
      top: 0;
      left: 0;
      // background: #161513;
      background: var(--black-gradient);
      height: 100%;
      margin: 0;
      z-index: 2;
      position: fixed;

      &.mobile-show {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 100px 0 0 40px;
      }

      a {
        display: flex;
        align-items: center;
        color: #f5f3f4;
        font-size: 28px;
      }
      a.home-link {
        &::after {
          padding-left: 10px;
          padding-top: 5px;
        }
      }
      a.about-link {
        &::after {
          padding-left: 15px;
          padding-top: 5px;
        }
      }
      a.projects-link {
        &::after {
          padding-left: 10px;
          padding-top: 5px;
        }
      }
      a.contact-link {
        &::after {
          padding-left: 10px;
        }
      }
      a:after {
        opacity: 1;
        position: initial;
        width: auto;
        margin-left: 10px;
        font-size: 18px;
      }

      a svg {
        opacity: 1 !important;
      }
    }

    .hamburger-icon,
    .close-icon {
      position: absolute;
      top: 25px;
      right: 30px;
      display: block;
    }
  }
}
