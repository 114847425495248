.contact-page {
  margin-left: 92px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  // width: 90%;
  h1 {
    font-size: 60px;
    line-height: 60px;
    // margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
    letter-spacing: 5px;
    // text-align: center;
    color: #ec795a;
    position: relative;
    margin-bottom: 50px;
    // left: -10px;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ec795a;
      font-size: 18px;
      position: absolute;
      margin-top: -15px;
      left: -20px;
      opacity: 0.6;
      line-height: 18px;
      letter-spacing: 2px;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #ec795a;
      font-size: 18px;
      line-height: 18px;
      position: absolute;
      left: -40px;
      bottom: -25px;
      margin-left: 20px;
      opacity: 0.6;
      letter-spacing: 2px;
    }
  }

  p {
    font-size: 16px;
    color: #f5f3f4;
    font-family: sans-serif;
    font-weight: 300;
    max-width: fit-content;
    animation: pulse 1s;
    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }
    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }

  .text-zone {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    width: 55%;
  }

  .text-animate-hover {
    &:hover {
      color: #f5f3f4;
    }
  }

  .contact-form {
    width: 100%;
    // text-align: center;
    margin-top: 20px;

    ul {
      padding: 0;
      li {
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
      }

      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    input[type='text'],
    input[type='email'] {
      width: 100%;
      border: 0;
      // background: #115173;
      background: #f5f3f4;

      height: 50px;
      font-size: 16px;
      // color: #fff;
      color: #1e2124;

      padding: 0 20px;
      box-sizing: border-box;
    }

    textarea {
      width: 100%;
      border: 0;
      // background: #115173;
      background: #f5f3f4;
      height: 50px;
      font-size: 16px;
      // color: #fff;
      color: #1e2124;
      padding: 20px;
      box-sizing: border-box;
      min-height: 150px;
    }
  }

  .map-wrap {
    background: rgba(8, 253, 216, 0.1);
    // float: right;
    height: 100%;
    // margin-top: -3%;
    width: 45%;
  }

  .leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
  }

  .info-map {
    position: absolute;
    background: #000;
    top: 50px;
    right: 20%;
    z-index: 999999;
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;

    span {
      font-size: 16px;

      span {
        color: #e05f3c;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-page {
    flex-direction: column;
    margin-left: 0;
    margin-left: 30px;
    margin-right: 30px;
    justify-content: center;
    align-items: center;
    height: 140vh;
    .text-zone {
      width: 100%;
      padding-top: 50px;
      h1 {
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 3px;
        margin-bottom: 50px;
      }
      p {
        font-size: 18px;
      }
      .flat-button {
        margin-bottom: 50px;
      }
    }
  }

  .map-wrap {
    // float: none;
    margin: 0;
    width: 100% !important;
    margin-bottom: 20px;
  }

  .info-map {
    // display: none;
    // top: 150px;
    // right: 20%;
    font-size: 18px !important;
    position: static !important;
    top: auto !important;
    right: auto !important;
    width: auto !important;
    margin-bottom: 30px !important;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    width: 100% !important;
  }
}
