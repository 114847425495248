.projects-page {
  margin-left: 92px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 100%;

  .text-zone {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 130px;
    h1 {
      font-size: 60px;
      line-height: 60px;
      font-family: 'Coolvetica';
      font-weight: 400;
      letter-spacing: 5px;
      color: #ec795a;
      position: relative;
      margin: 160px 0 32px;

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #ec795a;
        font-size: 18px;
        position: absolute;
        margin-top: -15px;
        left: -20px;
        opacity: 0.6;
        line-height: 18px;
        letter-spacing: 2px;
      }

      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #ec795a;
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -40px;
        bottom: -25px;
        margin-left: 20px;
        opacity: 0.6;
        letter-spacing: 2px;
      }
    }
    p {
      font-size: 16px;
      color: #f5f3f4;
      font-family: sans-serif;
      font-weight: 400;
      max-width: fit-content;
      animation: pulse 1s;
      .projects-btn1 {
        display: inline-block;
        margin: 0 20px;
        letter-spacing: 0px;
      }
    }

    .text-animate-hover {
      &:hover {
        color: #f5f3f4;
      }
    }
  }
  .projects-list {
    display: grid;
    gap: 48px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 60px;
    width: 82%;

    &_item {
      border-radius: 10px;
      box-shadow: 0 8px 35px 0 hsla(36, 25%, 65%, 0.15);
      padding: 48px 80px;
      transition: all 0.3s ease;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      aspect-ratio: 3 / 2;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 20px;
      gap: 15px;

      transition: transform 0.3s ease-in-out;
      &:hover {
        transform: translateY(-10px);
      }

      &_name {
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        color: #ec795a;
      }
      &_info {
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        color: #f5f3f4;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0);
        border-radius: 10px;
        transition: background-color 0.3s ease-in-out;
        pointer-events: none;
        z-index: 1;
      }
      &:hover::before {
        // background: rgba(0, 0, 0, 0.8);
        background: linear-gradient(
          -144.39deg,
          rgba(255, 255, 255, 0.5) -278.56%,
          rgba(109, 109, 109, 0.5) -78.47%,
          rgba(17, 16, 29, 0.9) 91.61%
        );
      }

      &_tech {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        &_elements {
          background: rgb(105, 104, 105);
          padding: 4px 8px;
          border-radius: 8px;
          font-size: 14px;
          color: #f5f3f4;
        }
      }
      &_links {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 25px;
        .flat-button {
          font-size: 16px;
          letter-spacing: 0;
          padding: 0.15em 0.5em;
        }
      }
      &_name,
      &_info,
      &_tech,
      &_links {
        opacity: 0;
        position: relative;
        z-index: 2;
      }
      &:hover {
        .projects-list_item_name,
        .projects-list_item_info,
        .projects-list_item_tech,
        .projects-list_item_links {
          opacity: 1;
        }
      }
    }
  }
  .projects-btn2 {
    margin: 50px 0;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 768px) {
  .projects-page {
    margin-left: 0;
    margin-left: 30px;
    margin-right: 30px;
    .text-zone {
      margin-left: 0;
      h1 {
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 3px;
        margin: 64px 0 32px;
      }
      p {
        .projects-btn1 {
          display: table;
          margin: 20px 0;
          font-size: 16px;
          letter-spacing: 0px;
        }
      }
    }
    .projects-list {
      display: grid;
      gap: 25px;
      grid-template-columns: repeat(1, 1fr);
      margin-top: 10px;
      width: 100%;
      &_item {
        background-size: cover;
        gap: 10px;
        padding: 10px;

        &:hover {
          transform: none;
          background-size: cover;
        }
        &_name {
          font-size: 24px;
          line-height: 14px;
          font-weight: 400;
        }
        &_info {
          font-size: 14px;
          line-height: 14px;
          font-weight: 400;
        }
        &_tech {
          gap: 3px;
          &_elements {
            padding: 2px 3px;
            border-radius: 4px;
            font-size: 12px;
          }
        }
        &_links {
          gap: 15px;
          .flat-button {
            font-size: 14px;
            letter-spacing: 0;
            padding: 0.1em 0.25em;
            // border: 0px;
          }
        }
        &_name,
        &_info,
        &_tech,
        &_links {
          opacity: 1;
          position: relative;
          // z-index: 2;
        }
        &::before {
          content: '';
          // background-color: rgba(0, 0, 0, 0.8);
          background: linear-gradient(
            -144.39deg,
            rgba(255, 255, 255, 0.3) -278.56%,
            rgba(109, 109, 109, 0.3) -78.47%,
            rgba(17, 16, 29, 0.9) 91.61%
          );
        }
        &:hover::before {
          // background: rgba(0, 0, 0, 0.9);
          background: linear-gradient(
            -144.39deg,
            rgba(255, 255, 255, 0.5) -278.56%,
            rgba(109, 109, 109, 0.5) -78.47%,
            rgba(17, 16, 29, 0.95) 91.61%
          );
        }
      }
    }
  }
}
