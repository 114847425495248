.home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 100vh;
  .text-zone {
    h1 {
      color: #f5f3f4;
      font-size: 72px;
      line-height: 64px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;
      letter-spacing: 5px;
      text-align: center;
      .name-h1 {
        line-height: 96px;
      }
      .job-h1,
      .location-h1 {
        font-size: 36px;
        display: block;
      }
      .job-h1 {
        margin-bottom: -10px;
      }
    }

    h2 {
      color: #ec795a;
      margin-top: 20px;
      margin-bottom: 40px;
      font-weight: 400;
      font-size: 28px;
      font-family: sans-serif;
      letter-spacing: 3px;
      animation: fadeIn 1s 1.8s backwards;
      text-align: center;
    }
    .links-mainpage {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 40px;
    }
  }
}

@media screen and (max-width: 768px) {
  .home-page {
    min-height: 90vh;
    .text-zone {
      h1 {
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 3px;
        margin: 10px 0;
        .name-h1 {
          line-height: 50px;
        }
        .job-h1,
        .location-h1 {
          font-size: 32px;
          display: block;
        }
        .job-h1 {
          margin-bottom: 0;
          max-width: 298px;
          span:last-child {
            display: inline-block;
            padding-right: 10px;
          }
        }
        img {
          width: 22px;
        }
      }
      h2 {
        font-size: 18px;
        letter-spacing: 2px;
        margin-bottom: 28px;
      }
    }
  }
}
